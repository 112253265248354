<template>
    <section class="moduloGrid cont">
        <OggettoHeadModulo :modulo="modulo" />
        <div :class="['grid', modulo.tipologia]">
            <template v-for="(card, index) in cards">
                <OggettoGenericoCard class="gridItem" v-if="!card.item?.lingue || card.item?.lingue.includes(locale)" :card="card" :typename="card.item?.__typename || card.collection" :width="calculateGridWidth(index)" :height="calculateGridHeight(index)" :mobileHeight="600" />
            </template>
        </div>
    </section>
</template>

<script lang="ts" setup>
const props = defineProps<{ modulo: any }>()
const { locale } = useI18n()
const { searchMeilimoduleCardsGrid } = useSearch()

const { data: generatedCards } = props.modulo.contenuto == 'automatico' && props.modulo.translations_formula[0] ? useAsyncData(JSON.stringify(props.modulo.translations_formula[0].formula), () => searchMeilimoduleCardsGrid(props.modulo.translations_formula[0].formula)) : { data: ref(null) }

const cards = computed(() => {
    if (props.modulo.contenuto == 'automatico' && generatedCards.value) return generatedCards.value
    else if (props.modulo.contenuto == 'manuale') return props.modulo.card
    else return []
})

const calculateGridWidth = (index: number) => {
    switch (props.modulo.tipologia) {
        case 'grid2':
            return 595
        case 'grid3':
            if (index == 2) return 716
            else return 474
        case 'grid4':
            if (index == 1 || index == 3) return 716
            else return 474
        case 'grid5':
        case 'grid7':
            if (index == 0 || index == 1 || index == 5 || index == 6) return 595
            else if (index == 2 || index == 3) return 474
            else return 353
        case 'gridn':
        case 'grid6':
        case 'grid8':
            const indexMod8 = index % 8
            if (indexMod8 == 0 || indexMod8 == 5) return 474
            if (indexMod8 == 1 || indexMod8 == 2 || indexMod8 == 3 || indexMod8 == 4) return 353
            if (indexMod8 == 6 || indexMod8 == 7) return 595
            else return 500
        case 'grid10':
            if (index == 0 || index == 1 || index == 2 || index == 5 || index == 6 || index == 7) return 353
            else if (index == 3 || index == 4) return 595
            else if (index == 8) return 716
            else return 474
        case 'col4':
            return 293
        case 'col5':
            return 232
        default:
            return undefined
    }
}
const calculateGridHeight = (index: number) => {
    switch (props.modulo.tipologia) {
        case 'grid2':
            return 400
        case 'grid3':
            if (index == 2) return 610
            else return 300
        case 'grid4':
            return 300
        case 'grid5':
        case 'grid7':
            if (index == 5 || index == 6) return 250
            else return 300
        case 'gridn':
        case 'grid6':
        case 'grid8':
            if (index % 8 == 6 || index % 8 == 7) return 250
            else return 300
        case 'grid10':
            if (index == 3 || index == 4) return 250
            else return 300
        case 'col4':
        case 'col5':
            return 400
        default:
            return undefined
    }
}
</script>

<style lang="scss" scoped>
.moduloGrid {

    width: calc(100% - (var(--margin-page) * 2));
    display: flex;
    flex-direction: column;
    gap: var(--article-gap-inner);

    .grid {
        display: grid;
        gap: var(--margin-gap);

        &.grid2 {
            grid-template-columns: repeat(2, minmax(0, 1fr));
            grid-template-rows: 400px;

            .gridItem {
                position: relative;
            }
        }

        &.grid3 {
            grid-template-columns: repeat(10, minmax(0, 1fr));
            grid-template-areas:
                "a a a a c c c c c c"
                "b b b b c c c c c c";

            .gridItem {
                position: relative;

                &:nth-of-type(1) {
                    grid-area: a;
                    height: 300px;
                }

                &:nth-of-type(2) {
                    grid-area: b;
                    height: 300px;
                }

                &:nth-of-type(3) {
                    grid-area: c;
                }
            }
        }

        &.grid4 {
            grid-template-columns: repeat(10, minmax(0, 1fr));

            grid-template-areas:
                "a a a a b b b b b b"
                "c c c c c c d d d d";

            .gridItem {
                position: relative;

                &:nth-of-type(1) {
                    grid-area: a;
                    height: 300px;
                }

                &:nth-of-type(2) {
                    grid-area: b;
                    height: 300px;
                }

                &:nth-of-type(3) {
                    grid-area: c;
                    height: 300px;
                }

                &:nth-of-type(4) {
                    grid-area: d;
                    height: 300px;
                }
            }
        }

        &.grid5,
        &.grid7 {
            grid-template-columns: repeat(10, minmax(0, 1fr));

            grid-template-areas:
                "a a a a a b b b b b"
                "c c c d d d e e e e"
                "f f f f f g g g g g";

            .gridItem {
                position: relative;

                &:nth-of-type(1) {
                    grid-area: a;
                    height: 300px;
                }

                &:nth-of-type(2) {
                    grid-area: b;
                    height: 300px;
                }

                &:nth-of-type(3) {
                    grid-area: c;
                    height: 300px;
                }

                &:nth-of-type(4) {
                    grid-area: d;
                    height: 300px;
                }

                &:nth-of-type(5) {
                    grid-area: e;
                    height: 300px;
                }

                &:nth-of-type(6) {
                    grid-area: f;
                    height: 250px;
                }

                &:nth-of-type(7) {
                    grid-area: g;
                    height: 250px;
                }
            }
        }

        &.gridn,
        &.grid6,
        &.grid8 {
            grid-template-columns: repeat(10, minmax(0, 1fr));
            grid-auto-flow: row dense;

            grid-template-areas:
                "a a a a b b b c c c"
                "d d d e e e f f f f"
                "g g g g g h h h h h";

            .gridItem {
                position: relative;

                &:nth-of-type(8n + 1) {
                    grid-area: auto / a;
                    height: 300px;
                }

                &:nth-of-type(8n + 2) {
                    grid-area: auto / b;
                    height: 300px;
                }

                &:nth-of-type(8n + 3) {
                    grid-area: auto / c;
                    height: 300px;
                }

                &:nth-of-type(8n + 4) {
                    grid-area: auto / d;
                    height: 300px;
                }

                &:nth-of-type(8n + 5) {
                    grid-area: auto / e;
                    height: 300px;
                }

                &:nth-of-type(8n + 6) {
                    grid-area: auto / f;
                    height: 300px;
                }

                &:nth-of-type(8n + 7) {
                    grid-area: auto / g;
                    height: 250px;
                }

                &:nth-of-type(8n + 8) {
                    grid-area: auto / h;
                    height: 250px;
                }
            }
        }

        &.grid10 {
            grid-template-columns: repeat(10, minmax(0, 1fr));
            grid-auto-flow: row dense;

            grid-template-areas:
                "a a a b b b c c c c"
                "d d d d d e e e e e"
                "f f f f g g g h h h"
                "i i i i i i j j j j";

            &:nth-of-type(1) {
                grid-area: a;
                height: 300px;
            }

            &:nth-of-type(2) {
                grid-area: b;
                height: 300px;
            }

            &:nth-of-type(3) {
                grid-area: c;
                height: 300px;
            }

            &:nth-of-type(4) {
                grid-area: d;
                height: 250px;
            }

            &:nth-of-type(5) {
                grid-area: e;
                height: 250px;
            }

            &:nth-of-type(6) {
                grid-area: f;
                height: 300px;
            }

            &:nth-of-type(7) {
                grid-area: g;
                height: 300px;
            }

            &:nth-of-type(8) {
                grid-area: h;
                height: 300px;
            }

            &:nth-of-type(9) {
                grid-area: i;
                height: 300px;
            }

            &:nth-of-type(10) {
                grid-area: j;
                height: 300px;
            }
        }

        &.col4 {
            grid-template-columns: repeat(4, minmax(0, 1fr));
            grid-auto-rows: 400px;

            .gridItem {
                position: relative;
            }
        }

        &.col5 {
            grid-template-columns: repeat(5, minmax(0, 1fr));
            grid-auto-rows: 400px;

            .gridItem {
                position: relative;
            }
        }
    }
}

@media screen and (max-width: 860px) {
    .moduloGrid {
        .grid {

            &.grid3 {
                grid-template-areas:
                    "a a a a a c c c c c"
                    "b b b b b c c c c c";

                .gridItem {
                    &:nth-of-type(1) {
                        height: 250px;
                    }

                    &:nth-of-type(2) {
                        height: 250px;
                    }
                }
            }

            &.grid5,
            &.grid7 {
                grid-template-areas:
                    "a a a a a b b b b b"
                    "c c c c c c c c c c"
                    "d d d d e e e e e e"
                    "f f f f f f g g g g";

                .gridItem {
                    &:nth-of-type(6) {
                        height: 300px;
                    }

                    &:nth-of-type(7) {
                        height: 300px;
                    }
                }
            }

            &.gridn,
            &.grid6,
            &.grid8 {
                grid-template-areas:
                    "a a a a a b b b b b"
                    "c c c c d d d d d d"
                    "e e e e e f f f f f"
                    "g g g g g g h h h h";

                .gridItem {
                    &:nth-of-type(8n + 7) {
                        height: 300px;
                    }

                    &:nth-of-type(8n + 8) {
                        height: 300px;
                    }
                }
            }

            &.grid10 {
                grid-template-areas:
                    "a a a a a b b b b b"
                    "c c c c d d d d d d"
                    "e e e e e f f f f f"
                    "g g g g g g h h h h"
                    "i i i i j j j j j j";

                &:nth-of-type(4) {
                    height: 300px;
                }

                &:nth-of-type(5) {
                    height: 300px;
                }
            }
        }
    }
}

@media screen and (max-width: 1060px) {
    .moduloGrid {
        .grid {

            &.col4,
            &.col5 {
                grid-template-columns: repeat(4, minmax(0, 1fr));
            }
        }
    }
}

@media screen and (max-width: 840px) {
    .moduloGrid {
        .grid {

            &.col4,
            &.col5 {
                grid-template-columns: repeat(3, minmax(0, 1fr));
            }
        }
    }
}

@media screen and (max-width: 600px) {
    .moduloGrid {
        .grid {
            display: flex;
            flex-direction: column;

            &.col4,
            &.col5 {
                display: grid;
                grid-template-columns: repeat(2, minmax(0, 1fr));

                .gridItem {
                    height: 400px !important;
                }
            }

            .gridItem {
                height: 300px !important;
            }
        }
    }
}

@media screen and (max-width: 480px) {
    .moduloGrid {
        .grid {

            &.col4,
            &.col5 {
                grid-template-columns: 1fr;
                grid-auto-rows: 300px;

                .gridItem {
                    height: 300px !important;
                }
            }
        }
    }
}
</style>